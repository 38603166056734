<template>
		<Dialog header="Header" v-model:visible="mostrarDlg" :style="{width: '60vw'}" :modal="true" :closable="false" :closeOnEscape="true">
			<template #header>				
				<div class="p-fluid p-m-1" >  
					<div class="p-field p-text-bold p-m-1">
					Seleccionar Documento				
					</div>
					<div class="p-field p-formgrid p-grid p-m-1" > 
						<div class="p-field p-col-3 p-m-1">
							<label for="desde" style="font-weight: 700;">Período</label>
							<SeleccionFecha @nuevoPeriodo="actualizarPeriodo"/>			
						</div>						
						<div class="p-field p-col-3 p-m-1">
							<label for="desde" style="font-weight: 700;">Desde</label>
							<Calendar id="desde" v-model="filtro.desde" :key="periodoUpdate"/>
						</div>		
						<div class="p-field p-col-3 p-m-1">
							<label for="hasta" style="font-weight: 700;">Hasta</label>
							<Calendar id="hasta" v-model="filtro.hasta" :key="periodoUpdate"/>
						</div>							
						<div class="p-field p-col-4 p-mt-7 p-m-1">
							<label for="procesarAnulados" style="font-weight: 700;">Mostrar solo {{ tituloTrx }} anuladas</label>
							<Checkbox class="p-ml-2" v-model="reprocesarAnulado" :binary="true" />
						</div>						
						<div class="p-field p-col-5 p-mt-2 p-ml-1">
							<label for="buscar"></label>
							<Button label="Buscar" icon="buscarCtaHabienteIcon" class="p-button-primary" @click="buscarMovs" />								
						</div>	
					</div>			
				</div>
			</template>
			<div class="p-fluid" >			
				<DataTable :value="lkpMovs" :scrollable="true" scrollHeight="flex" :loading="origenDialogLoading"
				v-model:selection="selMov" selectionMode="single" dataKey="id_movimiento" @row-select="seleccionarDocumento">
					<Column field="fecha" header="Fecha" style="width:50px"></Column>
					<Column field="movimiento_tipo" header="Tipo" style="width:50"></Column>
					<Column field="documento" header="#Docto" style="width:50"></Column>
					<Column field="nombre_documento" header="Cta.Habiente" style="width:100px"></Column>
					<Column field="referencia" header="Referencia" style="width:100px"></Column>	
					<!-- <Column field="id_moneda" header="Moneda" style="width:100px">
						<template #body="slotProps">
							<div> {{ slotProps.data.id_moneda }}</div>
						</template>						
					</Column>	 -->
					<Column field="saldo" header="Saldo" :sortable="true" headerStyle="width: 10rem" :style="reprocesarAnuladoSel?'display: none':''">
						<template #body="slotProps">
							<div class="p-d-flex p-jc-end" :style="slotProps.data.id_moneda==IdMonedaSel?'':'color:red;'">
								<div class="p-d-flex p-jc-end">{{slotProps.data.id_moneda}} {{ formatCurrency(slotProps.data.id_moneda, slotProps.data.saldo) }}</div>
							</div>   												
						</template>						
					</Column>		
					<Column field="total" header="Total" :sortable="true" headerStyle="width: 10rem" >
						<template #body="slotProps">
							<!-- {{ formatCurrency(slotProps.data.id_moneda, slotProps.data.total) }} -->
							<div class="p-d-flex p-jc-end" :style="slotProps.data.id_moneda==IdMonedaSel?'':'color:red;'">
								<div class="p-d-flex p-jc-end">{{slotProps.data.id_moneda}} {{ formatCurrency(slotProps.data.id_moneda, slotProps.data.total) }}</div>
							</div>   												
						</template>						
					</Column>										
					<template #empty>
						<div>
						No hay datos para mostrar
						</div>
					</template>
				</DataTable>					
			</div>
		<template #footer>
			<Button label="Cancelar" icon="pi pi-times" @click="cancelarBuscarMovOrigen" class="p-button-text"/>
			<Button label="Seleccionar" icon="pi pi-check" @click="elegirMovOrigen" autofocus :disabled="!movSelected"/>
		</template>
	</Dialog>
</template>

<script>
import SeleccionFecha from '../components/SeleccionFecha.vue';
import TrxVue from '../pages/Trx.vue';
import InvService from '../service/inv.service';

	export default {
		name: 'BuscarMovOrigen',
		props: {
			mostrar: {type: Boolean, required: true},
			id_cta_habiente_info: {type: String, required: false},
			id_movimiento_tipo: {type: String, required: false},
			id_moneda:  {type: String, required: false},
			titulo:  {type: String, required: false}
		},
		components: { SeleccionFecha },
		data() {
				return {					
					periodoUpdate: 0,
					reprocesarAnulado: false,
					reprocesarAnuladoSel: false,
					filtro: {
						id: this.$store.state.auth.currentAppCtx.id_referencia,
						id_sucursal: this.$store.state.auth.currentAppCtx.Sucursales[0].id_sucursal,
						id_cta_habiente_info: this.id_cta_habiente_info,
						anulado: 'N',
						id_tipo_doc: this.id_movimiento_tipo,
						id_moneda: this.id_moneda,
						serie: '',
						no_docto: 0,
						desde: new Date(),
						hasta: new Date()				
					},
					IdMonedaSel: null,
					tituloTrx: this.titulo,
					selMov: null,
					origenDialog: null,				
					origenDialogLoading: false,
					lkpMovs: []
				}
		},
		computed: {
			mostrarDlg() {
				return this.mostrar;
			},
			movSelected() {
				if(!this.selMov) {
					return false;
				}
				
				console.log(`Mov Id Moneda: ${this.selMov.id_moneda}`);
				console.log(`Filtro Id Moneda: ${this.filtro.id_moneda}`);

				if(this.selMov.id_moneda == this.filtro.id_moneda) {
					return true;
				} else {
					return false;
				}
			}
		},
		mounted() {
			this.buscarMovs();
		},
		methods: {
			cancelarBuscarMovOrigen() {
				this.visible = false;
				this.$emit('cancelar');				
			},
			seleccionarDocumento()
			{
				if((this.lkpMovs.length == 1)&&(this.movSelected)) {
					this.elegirMovOrigen();
				}
			},
			elegirMovOrigen() {				
				this.$emit('seleccionar', this.selMov);
				this.selMov = null;
			},
			actualizarPeriodo(periodo)
			{
				this.filtro.desde = periodo.desde;
				this.filtro.hasta = periodo.hasta;
				this.periodoUpdate += 1;
			},			
			seleccionarMov() {
				this.origenDialog = true;
			},
			formatCurrency(moneda, value) {
				return parseFloat(value).toLocaleString('en-US', {minimumFractionDigits: 2});
				// if(value)
				// 	return value.toLocaleString('en-US', {style: 'currency', currency: moneda});
				// return;
			},			
			async buscarMovs() {			
				this.origenDialogLoading = true;
				this.selMov = null;	
				this.IdMonedaSel = this.filtro.id_moneda;
				this.reprocesarAnuladoSel = this.reprocesarAnulado;
				try {
					var data = null;
					if(this.reprocesarAnulado) {
						data = await InvService.buscaMovsAnulados(this.$store.state.auth.currentAppCtx, this.filtro);
					} else {
						data = await InvService.buscaMovsOrigen(this.$store.state.auth.currentAppCtx, this.filtro);
					}
					
					this.lkpMovs = data;
					this.origenDialogLoading = false;
				}
				catch(data){					
					this.origenDialogLoading = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000});
				}					
			}
		},			
	}
</script>

<style scoped>
</style>
